import React from 'react';
import {navigate} from 'gatsby';
import {Button} from 'antd';
import styled from 'styled-components';
import StaticImage from '../../../src/Components/StaticImage';
const appConfig = require('../../../src/data.json');

function IntroductionSection(props) {
  return (
    <Wrapper>
      <div className="container" style={{margin: 10, padding: 0}}>
        <div className="logo">
          <img
            alt="logo-word.png"
            src="/images/logo-word.png"
            style={{maxWidth: 450, width: '100%'}}
          />
        </div>
      </div>
      <div className="container">
        <h3>建鏵數位印刷掌握最佳的數位印刷製程！</h3>
        <p>
          {`不印不知道，來印就回不去了！\n需要高品質的少量印刷嗎？建鏵為您提供服務！\n建鏵印刷為您提供多種不同的選擇，UV印刷的貼紙顏色鮮豔，有特殊的磨砂質感，甚至還可以重覆黏貼不殘膠，不須開刀模就可以讓你的貼紙有切割線，不像外面一次印就需要大量下單～建鏵給你最好的選擇！`}
        </p>
        <div className="button-container">
          <Button
            type="primary"
            size="large"
            onClick={() => navigate('/products')}>
            立刻前往客製
          </Button>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;

  & > .container {
    padding: 15px;
    flex: 1;

    & > .logo {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;
    }

    & > h3 {
      margin-bottom: 31px;
      font-size: ${appConfig.headingSize.md}px;
      color: ${appConfig.colors.main};
    }

    & > p {
      max-width: 567px;
      width: 100%;
      white-space: pre-wrap;
      font-size: ${appConfig.fontSize.md}px;
      color: ${appConfig.colors.text};
    }
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    flex-direction: column;
    align-items: center;

    & > .container {
      padding: 15px;
      & > .logo {
        width: 200px;
      }

      & > h3 {
        text-align: center;
      }

      & > p {
        text-align: center;
      }

      & > .button-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export default IntroductionSection;
