import React from 'react';
import {Button} from 'antd';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import StaticImage from '../../../src/Components/StaticImage';
const appConfig = require('../../../src/data.json');

const INFO_LIST = [
  `● 客服電話：${appConfig.information.tel}\n`,
  `● 客服信箱：${appConfig.information.email}\n`,
  `● 營業時間：${appConfig.information.service_time}\n`,
  `● 門市地址：${appConfig.information.address}\n`,
  `● FB粉絲專頁：${appConfig.socialMedia.facebook.name}\n`,
  `● 官方LINE@：${appConfig.socialMedia.line.name}\n`,
  `● 蝦皮帳號：${appConfig.socialMedia.shopee.name}\n`,
];

function InformationSection(props) {
  return (
    <Wrapper>
      <Content>
        <div className="container">
          <div className="title-container">
            <div className="icon">
              <StaticImage
                filename="about-info-icon-01.png"
                style={{width: '100%', height: '100%'}}
              />
            </div>
            <div className="text">{'專人線上1對1專人客服\n歡迎聯繫客服'}</div>
          </div>
          <div className="content-container">
            {INFO_LIST.map((item) => item)}
          </div>
        </div>
        <div className="container center">
          <StaticImage
            filename="about-info-01.png"
            style={{width: 260, height: 158}}
          />
        </div>
      </Content>
      {/* <Content>
        <div className="container center" style={{marginTop: 50}}>
          <Button
            type="primary"
            size="large"
            style={{
              flexGrow: 0,
              border: 0,
            }}
            onClick={() => navigate('/faq')}>
            聯繫客服
          </Button>
        </div>
      </Content> */}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const Content = styled.div`
  display: flex;

  & > .container {
    flex: 1;
  }

  & .title-container {
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    width: 100%;

    & > .icon {
      margin-right: 20px;
      width: 75px;
      height: 75px;
    }

    & > .text {
      font-size: ${appConfig.headingSize.lg}px;
      white-space: pre-wrap;
      line-height: 1.33;
      letter-spacing: 0.9px;
      color: ${appConfig.colors.main};
    }
  }

  & .content-container {
    padding-left: 10px;
    line-height: 2.25;
    white-space: pre-wrap;
    font-size: ${appConfig.fontSize.md}px;
    color: ${appConfig.colors.text};
  }

  & .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    flex-direction: column;

    & .title-container {
      margin-bottom: 20px;
      justify-content: center;

      & > .text {
        font-size: ${appConfig.headingSize.sm}px;
      }
    }

    & .content-container {
      margin-bottom: 20px;
    }
  }
`;

export default InformationSection;
