import React from 'react';
import styled from 'styled-components';
const appConfig = require('../../../src/data.json');

const FLOW_LIST = [
  {icon: '/images/about-service-icon-01.png', title: '聯繫客服需求接洽'},
  {icon: '/images/about-service-icon-02.png', title: '官網下單及付款'},
  {icon: '/images/about-service-icon-03.png', title: '上傳稿件及審稿'},
  {icon: '/images/about-service-icon-04.png', title: '商品印製生產'},
  {icon: '/images/about-service-icon-05.png', title: '出貨及完成取件'},
];

function FlowSection(props) {
  return (
    <Wrapper>
      {FLOW_LIST.map((item, index) => {
        if (index + 1 === FLOW_LIST.length) {
          return (
            <FlowItemContainer>
              <div className="item">
                <img alt="about-service-icon" src={item.icon} />
                <div>{item.title}</div>
              </div>
            </FlowItemContainer>
          );
        }

        return (
          <>
            <FlowItemContainer>
              <div className="item">
                <img alt="about-service-icon" src={item.icon} />
                <div>{item.title}</div>
              </div>
            </FlowItemContainer>
            <DividerContainer>
              <div className="arrow-container">
                <div className="arrow" />
              </div>
              <div className="line" />
            </DividerContainer>
          </>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  --item-size: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: ${appConfig.breakpoints.sm}px) {
    flex-direction: column;
  }
`;

const FlowItemContainer = styled.div`
  margin: 10px;
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .item {
    & > img {
      width: var(--item-size);
      height: var(--item-size);
    }

    & > div {
      text-align: center;
      font-weight: 300;
      font-size: ${appConfig.fontSize.md}px;
    }
  }
`;

const DividerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .arrow-container {
    width: 20px;
    height: var(--item-size);
    display: flex;
    align-items: center;
    position: relative;

    & > .arrow {
      width: 0px;
      height: 0px;
      border-top: 10px solid #777a7f;
      border-bottom: 10px solid transparent;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;

      transform: rotate(-90deg);
      position: absolute;
      left: 4.5px;
    }
  }

  & > .line {
    width: 1px;
    height: 20px;
    background-color: lightgray;
  }

  @media screen and (max-width: ${appConfig.breakpoints.sm}px) {
    flex-direction: row-reverse;
    & > .arrow-container {
      height: 40px;

      & > .arrow {
        transform: rotate(0deg);
        bottom: 1px;
      }
    }

    & > .line {
      margin-right: 10px;
      width: 100px;
      height: 1px;
    }
  }
`;

export default FlowSection;
