import React, {useContext, useState} from 'react';
import {Context} from '../../../src/AppContext';
import {Link} from 'gatsby';
import {Button, Input, message} from 'antd';
import styled from 'styled-components';
import StaticImage from '../../../src/Components/StaticImage';
import IntroductionSection from './IntroductionSection';
import FlowSection from './FlowSection';
import ServiceSection from './ServiceSection';
import PartnersSection from './PartnersSection';
import InformationSection from './InformationSection';
import ContactSection from './ContactSection';
const appConfig = require('../../../src/data.json');

const MAX_WIDTH = appConfig.maxContentWidth;

export default function AboutPage(props) {
  return (
    <Wrapper bannerImg="/images/about-banner.jpg">
      <div className="banner" />

      <div className="section" style={{paddingTop: 80, paddingBottom: 80}}>
        <div className="constraint">
          <IntroductionSection />
        </div>
      </div>

      <div className="section" style={{padding: 0}}>
        <h3 className="title">SERVICE PROCESS｜服務流程</h3>
        <div className="constraint" style={{marginBottom: 80}}>
          <FlowSection />
        </div>
        <ServiceSection />
      </div>

      <div className="section" style={{paddingTop: 100}}>
        <h3 className="title">數位印刷｜UV印刷｜防水貼紙｜急件印製｜高品質</h3>
        <div className="constraint">
          <PartnersSection />
        </div>
      </div>

      <div className="section">
        <h3 className="title">Information | 商家資訊</h3>
        <div className="constraint">
          <InformationSection />
        </div>
      </div>

      <div
        className="section"
        style={{backgroundColor: appConfig.colors.third}}>
        <h3 className="title">Contact Us | 聯絡我們</h3>
        <p className="desp">
          {`對於我們有任何問題與報價需求，歡迎填寫表單與我們聯絡！\n也可直接加入我們官方LINE${appConfig.socialMedia.line.name}`}
        </p>
        <div className="constraint">
          <ContactSection />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .constraint {
    margin: 0 auto;
    max-width: ${MAX_WIDTH + 40}px;
  }

  & > .banner {
    background-color: #ececec;
    width: 100%;
    height: 400px;
    overflow: hidden;

    background-image: url(${(props) => props.bannerImg});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: 600px) {
      height: 250px;
    }
  }

  & > .section {
    padding: 50px 20px;
    width: 100%;

    & > .title {
      margin-bottom: 36px;
      text-align: center;
      color: ${appConfig.colors.main};
      font-weight: 500;
      font-size: 26px;
      letter-spacing: 1px;
    }

    & > .desp {
      margin-bottom: 44px;
      text-align: center;
      color: #717071;
      white-space: pre-wrap;
      letter-spacing: 1.8px;
      line-height: 1.67;
    }

    @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
      & > .title {
        font-size: 20px;
      }
    }
  }
`;
