import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import Carousel from 'nuka-carousel';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@styled-icons/material';
import useDimension from '../../../src/hooks/use-dimension';
import StaticImage from '../../../src/Components/StaticImage';
const appConfig = require('../../../src/data.json');

function PartnersSection(props) {
  const {dimension} = useDimension();
  console.warn('dimension', dimension);

  return (
    <Wrapper>
      <Carousel
        autoplay={true}
        width="92%"
        renderCenterLeftControls={({previousSlide}) => (
          <Button
            size="small"
            shape="circle"
            icon={<KeyboardArrowLeft size={24} />}
            onClick={previousSlide}
            style={{
              border: 0,
              position: 'absolute',
              left: '-40px',
            }}
          />
        )}
        renderCenterRightControls={({nextSlide}) => (
          <Button
            size="small"
            shape="circle"
            icon={<KeyboardArrowRight size={24} />}
            onClick={nextSlide}
            style={{
              border: 0,
              position: 'absolute',
              right: '-40px',
            }}
          />
        )}
        renderBottomCenterControls={(props) => {
          let {currentSlide, goToSlide, slideCount} = props;
          let indexes = Array.from(Array(slideCount).keys());
          return (
            <Indicator>
              {indexes.map((idx) => (
                <IndicatorDot
                  highlight={idx === currentSlide}
                  onClick={() => goToSlide(idx)}
                />
              ))}
            </Indicator>
          );
        }}>
        {new Array(8).fill('').map((img, index) => (
          <StaticImage filename={`about-partner${index + 1}.jpg`} />
        ))}
      </Carousel>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
`;

const Indicator = styled.div`
  display: flex;
  position: absolute;
  bottom: -30px;
  transform: translatex(-50%);
`;

const IndicatorDot = styled.div`
  margin: 8px;
  cursor: pointer;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.highlight ? appConfig.colors.main : '#ffffff'};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
`;

export default PartnersSection;
