import React from 'react';
import styled from 'styled-components';
import StaticImage from '../../../src/Components/StaticImage';
import useDimension from '../../../src/hooks/use-dimension';
const appConfig = require('../../../src/data.json');

const COMPONENT_BREAKPOINT = 980;

function ServiceSection(props) {
  let {dimension} = useDimension();
  let [scrollbarWidth, setScrollbarWidth] = React.useState(0);

  React.useEffect(() => {
    if (dimension.innerWidth >= COMPONENT_BREAKPOINT) {
      setScrollbarWidth(dimension.innerWidth - document.body.clientWidth);
    }
  }, [dimension.innerWidth]);

  return (
    <Wrapper>
      <div className="row">
        <InnerWrapper>
          {[
            {
              image: '/images/about-service-01.jpg',
              icon: '/images/about-service-icon-06.png',
              title: '服務',
              desp: '專人一對一諮詢，從印刷諮詢至售後服務，實現顧客至上。',
            },
            {
              image: '/images/about-service-02.jpg',
              icon: '/images/about-service-icon-07.png',
              title: '誠心',
              desp: '以客戶立場提供適合的印刷服務，用心對待每份印刷作品。',
            },
          ].map((item, index) => (
            <ItemsWrapper
              reverse={
                dimension.innerWidth >= appConfig.breakpoints.sm
                  ? index === 1
                  : true
              }>
              <ImageOnlyItem src={item.image} scrollbarWidth={scrollbarWidth} />
              <ServiceItem scrollbarWidth={scrollbarWidth}>
                <img alt="icon" src={item.icon} />
                <div className="title">{item.title}</div>
                <div className="desp">{item.desp}</div>
              </ServiceItem>
            </ItemsWrapper>
          ))}
        </InnerWrapper>
      </div>
      <div className="row">
        <InnerWrapper reverse={true}>
          {[
            {
              image: '/images/about-service-03.jpg',
              icon: '/images/about-service-icon-08.png',
              title: '創新',
              desp: '創新的印刷電商型態，以消費者角度思考，提供新式印刷服務。',
            },
            {
              image: '/images/about-service-04.jpg',
              icon: '/images/about-service-icon-09.png',
              title: '品質',
              desp: '專人審查稿件及看印，專業師傅高品質製作，質量值得您安心！',
            },
          ].map((item, index) => (
            <ItemsWrapper
              reverse={
                dimension.innerWidth >= appConfig.breakpoints.sm
                  ? index === 1
                  : false
              }>
              <ServiceItem scrollbarWidth={scrollbarWidth}>
                <img alt="icon" src={item.icon} />
                <div className="title">{item.title}</div>
                <div className="desp">{item.desp}</div>
              </ServiceItem>
              <ImageOnlyItem src={item.image} scrollbarWidth={scrollbarWidth} />
            </ItemsWrapper>
          ))}
        </InnerWrapper>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;

  & .row {
    display: flex;
    width: 100%;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: ${COMPONENT_BREAKPOINT}px) {
    flex-direction: ${(props) => (props.reverse ? 'column-reverse' : 'column')};
  }
`;

const ItemsWrapper = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: ${COMPONENT_BREAKPOINT}px) {
    flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  }

  @media screen and (max-width: ${appConfig.breakpoints.sm}px) {
    flex-direction: ${(props) => (props.reverse ? 'column-reverse' : 'column')};
  }
`;

const ImageOnlyItem = styled.div`
  width: calc((100vw - ${(props) => props.scrollbarWidth}px) / 4);
  height: calc((100vw -${(props) => props.scrollbarWidth}px) / 4 * 0.82);

  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px;

  @media screen and (max-width: ${COMPONENT_BREAKPOINT}px) {
    width: calc((100vw - ${(props) => props.scrollbarWidth}px) / 2);
    height: calc((100vw -${(props) => props.scrollbarWidth}px) / 2 * 0.82);
  }

  @media screen and (max-width: ${appConfig.breakpoints.sm}px) {
    width: 100%;
    height: 300px;
  }
`;

const ServiceItem = styled.div`
  width: calc((100vw - ${(props) => props.scrollbarWidth}px) / 4);
  height: calc((100vw -${(props) => props.scrollbarWidth}px) / 4 * 0.82);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${appConfig.colors.third};

  & > img {
    margin-bottom: 5px;
    width: 95px;
    height: 95px;
  }

  & > .title {
    max-width: 158px;
    width: 100%;
    border-bottom: 1px solid ${appConfig.colors.main};
    padding-bottom: 15px;
    line-height: 1.03;
    text-align: center;
    letter-spacing: 7.2px;
    font-size: ${appConfig.fontSize.xl}px;
  }

  & > .desp {
    max-width: 250px;
    width: 100%;
    padding-top: 15px;
    line-height: 1.88;
    text-align: center;
    color: ${appConfig.colors.text};
  }

  @media screen and (max-width: ${COMPONENT_BREAKPOINT}px) {
    width: calc((100vw - ${(props) => props.scrollbarWidth}px) / 2);
    height: calc((100vw - ${(props) => props.scrollbarWidth}px) / 2 * 0.82);
  }

  @media screen and (max-width: ${appConfig.breakpoints.sm}px) {
    width: 100%;
    height: 300px;
  }
`;

export default ServiceSection;
